import React, { useEffect, useState } from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';
import { modalContents } from 'constants/modal-content';
import { LinkModal } from 'kh-common-components';

import Link from 'components/common/Link';
import Faq from 'components/common/Faq';
import RacPlans from 'components/cards/RacPlans';
import RacNgProductHighlight from 'components/cards/RacNgProductHighlight';

import racLogo from 'images/rac/natural-gas/kleenheat-rac-logo.png';
import heroBannerSoap from 'images/rac/natural-gas/hero-banner-soap.png';
import flowmoji50Dark from 'images/rac/natural-gas/50-flowmoji-dark.svg';

import RacPage from 'pages/rac/index';

import { useToggleRac50Expired } from 'constants/hooks/marketingEventToggles';
import faqDataOffer from 'constants/components/accordion-list/rac-ng-faq';

import 'styles/pages/rac-offer-ended.css';

function RacNaturalGasPage() {
    const isPromoRac50Expired = useToggleRac50Expired();

    return (
        <>
            {isPromoRac50Expired === null ? (
                <div></div>
            ) : isPromoRac50Expired ? (
                <RacPage />
            ) : (
                <RacNaturalGasPageOld />
            )}
        </>
    );
}

export function RacNaturalGasPageOld() {

    const [racOfferPromo, setRacOfferPromo] = useState(process.env.RAC_OFFER_PROMO);
    const [racOfferPromoTerms, setRacOfferPromoTerms] = useState('racbonus50-ng-terms');
    const [racOfferPromoPlansTerms, setRacOfferPromoPlansTerms] = useState('racbonus50-ng-plans-terms');


	useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sourceQuery = urlParams.get('utm_source');

        if (sourceQuery && sourceQuery.toUpperCase() === 'RAC')  {
            setRacOfferPromo('RAC38AND50');
            setRacOfferPromoTerms('rac38and50-ng-terms');
            setRacOfferPromoPlansTerms('rac38and50-ng-plans-terms');
        }

    }, []);

    return (
		<>
            <SEO title="RAC Member Exclusive Offer | Natural Gas – Kleenheat" 
                 titleBespoke="true"
            	 description="RAC members enjoy exclusive savings on natural gas usage charges with Kleenheat. Switch to WA’s local gas supplier today."
                 meta={[{name:"robots", content:'noindex'}]}
        	/> 
 
            <Header />

            <LayoutMain>

                <LayoutPanel theme="mer-theme--cyan"
                    		 background="mer-bg--primary-cyan"
                    		 padding="mer-py-lg">
	                <div className="container rac-ng-hero-banner">
	                    <div className="row mer-panel">
	                        <div className="col-12 col-md-10 col-lg-5 mer-text--size-lg offset-lg-1 order-2 py-0">
                                <div className="row">
                                    <div className="col-8 col-sm-6 col-lg-10 col-xl-8 mer-panel__images mx-auto ml-lg-n4 mb-0 p-0">
                                    <img src={racLogo}
                                        className="mer-panel__image kleenheat-rac-logo"
                                        alt="Kleenheat and RAC logo" />
                                    </div>

                                    <div className="col-12 text-center text-lg-left p-0">
                                        <h1 className="mer-typography--headline2 mt-0">RAC member exclusive offer.</h1>
                                        <p><strong>RAC members save 38% off natural gas usage charges <span className="mer-text--no-wrap">for 12 months</span>, plus get a $50 account credit.<sup>*</sup></strong></p>
                                        <p>Switch to our Monthly Energiser plan by <span className="mer-text--no-wrap">11:59pm</span>, <span className="mer-text--no-wrap">30 November 2024</span>.</p>

                                        <div className="mer-panel__actions mer-button-group justify-content-center justify-content-lg-start">
                                            <Link text="Sign up"
                                                link={`/sign-up/promo/${racOfferPromo}`}
                                                linkClass="mer-button mer-button--primary" />                                                              
                                            <Link text="View plans"
                                                link="#plans"
                                                linkClass="mer-button mer-button--secondary" />
                                        </div>

                                        <p className="mer-typography--body3 mt-4"><sup>*</sup>Offer is available to new customers only.&nbsp;
                                            <LinkModal 
                                                modalContents={modalContents}
                                                useModal="true"
                                                displayContent="Terms and conditions apply."
                                                title="RAC offer terms and conditions"
                                                modalTitle="RAC offer terms and conditions"
                                                modalContentName={racOfferPromoTerms}
                                                okayButtonLabel="Close"
                                            />
                                        </p>		
                                    </div>
                                </div>
                            </div>

	                        <div className="mer-panel__images col-8 col-md-5 col-lg-4 order-1 py-0">
	                            <img src={heroBannerSoap} 
	                                 className="mer-panel__image hero-banner-soap"
	                                 alt="Flowmoji soap" />
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

                <RacNgProductHighlight panelBackground="mer-bg--ui-light"
								     panelPadding="mer-pt-lg" />

                <LayoutPanel background="mer-bg--primary-royal"
		    				 theme="mer-theme--dark"
			                 padding="mer-py-lg">
			        <div className="container">
			            <div className="row mer-panel">

							<div className="col-12 col-md-10 col-lg-5 offset-lg-1 order-2">
                                <div className="content text-center text-lg-left">
                                    <h2>Limited time offer</h2>
                                    <p>We've partnered with RAC to offer ongoing local value to our customers. RAC members who switch to our Monthly Energiser plan save 38% off natural gas usage charges for 12 months, plus get a $50 account credit.*</p>  
                                    <p><sup>*</sup>Offer is available to new customers only.&nbsp;
                                        <LinkModal 
                                            modalContents={modalContents}
                                            useModal="true"
                                            linkClass="mer-color--gs-white"
                                            displayContent="Terms and conditions apply."
                                            title="RAC offer terms and conditions"
                                            modalTitle="RAC offer terms and conditions"
                                            modalContentName={racOfferPromoPlansTerms}
                                            okayButtonLabel="Close"
                                        />
                                    </p>								
                                </div>
							</div>

							<div className="col-7 col-sm-4 col-xl-3 mer-panel__images order-1 my-n4 m">
                                <img src={flowmoji50Dark} 
	                                 className="mer-panel__image"
	                                 alt="$50 credit" />
							</div>
			            </div>
			        </div>
			    </LayoutPanel>

                <RacPlans racOfferPromo={racOfferPromo}
                          racOfferPromoTerms={racOfferPromoTerms} />

                <Faq panelBackground="mer-bg--gs-grey-lighter"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqDataOffer}
                     description={<p className="text-center">
                                    <LinkModal 
                                        modalContents={modalContents}
                                        useModal="true"
                                        displayContent={<strong className="mer-typography--body2">View full terms and conditions</strong>}
                                        title="RAC offer terms and conditions"
                                        modalTitle="RAC offer terms and conditions"
                                        modalContentName={racOfferPromoPlansTerms}
                                        okayButtonLabel="Close"
                                    />
                                </p>} />


                <LayoutPanel background="mer-bg--primary-cyan"
                            padding="mer-py-lg">
                    <div className="container">
                        <div className="row justify-content-center align-items-middle">
                            <div className="col-12 col-sm-10 col-lg-8 mer-text--align-center mer-text--size-lg">
                                <h2 className="my-0 mer-color--primary-navy">Take advantage of this exclusive offer</h2>
                                <div className="col-12 col-sm-4 col-md-3 mer-panel__actions mer-button-group flex-column mx-auto mt-2 mer-theme--cyan">
                                    <a href={`/sign-up/promo/${racOfferPromo}`} className="mer-button mer-button--primary">Sign up</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </LayoutPanel>


            </LayoutMain>
            <Footer />

        </>
    )
}

export default RacNaturalGasPage;